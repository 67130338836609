<template>
  <div class="page-container">
    <TopNavbar @lang="Language"/>
    <MainContent :ExportLang='ddlang'/>
    <InfoContainer :ExportLang='ddlang'/>
    <BottomFooter :ExportLang='ddlang'/>
  </div>
</template>
<script>
import TopNavbar from './components/TopNavbar.vue'
import InfoContainer from "@/components/InfoContainer";
import MainContent from "@/components/MainContent";
import BottomFooter from "@/components/BottomFooter";


export default {
  name: 'App',
  components: {
    TopNavbar,
    InfoContainer,
    MainContent,
    BottomFooter
  },
  methods:
      {
        Language(ll)
        {
        this.ddlang = ll
        }
      },
  data(){
    return{
      ddlang: true
    }
  }




}
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  :root {
    --bg-color-white: #ffffff;
    --bg-color-lightgray: #DADDE2;
    --text-color-gray: #959595;
    --text-color-black: #1E2022;
    --cloud-blue-color: #DBE2EF;
    --light-blue-color: rgba(0, 180, 216, 1);
    --dark-blue-color: #197c90;
  }

  #app {
    width: 100vw;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .page-container {
    width: 100vw;
    user-select: none;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    padding: 0 !important;
  }

  .title {
    font-size: 40px;
    text-transform: uppercase;
    background-color: var(--light-blue-color);
    color: #ffffff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    transition: all .2s;
    -webkit-box-shadow: 13px 10px 0px 5px rgba(0, 180, 216, 0.24);
    -moz-box-shadow: 13px 10px 0px 10px rgba(0, 180, 216, 0.24);
    box-shadow: 13px 10px 0px 5px rgba(0, 180, 216, 0.24);
  }

  .title:hover {
    background-color: var(--bg-color-white);
    color: var(--light-blue-color);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-shadow: 6px 6px 0px rgba(0, 180, 216, 0.24);
  }

  .line {
    height: 1px;
    background: var(--bg-color-white);
  }

  .btn {
    border: none;
    border-radius: 0;
    background: transparent;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }

  .shape1 {
    width: 30vw;
    height: 20vh;
    background: rgba(0, 180, 216, 0.24);
    position: absolute;
    z-index: -1;
  }
</style>
