<template>
  <div class="footer-container">
    <div class="footer-sides-container">
      <div class="left-footer-container">
        <h1 class="bot-title">FlyPL Virtual</h1>
        <p v-if="ExportLang" id="left-subtitle">FlyPL to wirtualna linia lotnicza (organizacja non-profit) przeznaczona wyłącznie do celów symulacji lotu. Nie jesteśmy finansowani ani zasilani przez żadną organizację z realnego świata. Wszystkie loga znaków towarowych należą do ich prawowitych właścicieli.</p>
        <p  v-else id="left-subtitle">FlyPL is a virtual airline (non-profit organization) intended solely for flight simulation purposes. We are not funded or powered by any organization in the real world. All trademark logos belong to their rightful owners.</p>
      </div>
      <div class="right-footer-container">
        <div>
          <h1 v-if="ExportLang" class="bot-title">Przydatne Linki</h1>
          <h1 v-else class="bot-title">Useful links</h1>
          <ul v-if="ExportLang" class="links">
<!--            <li><a href="#">FAQ</a></li>-->
            <li><a href="mailto:flyprovapl@gmail.com">Skontaktuj się z nami</a></li>
<!--            <li><a href="#">Polityka Cookie</a></li>-->
<!--            <li><a href="#">Polityka prywatności</a></li>-->
            <li><a href="https://vms.flyprova.pl/uploads/files/FlyPL%20Virtual%20Terms%20and%20Conditions.pdf">Terms and Conditions</a></li>
          </ul>
          <ul v-else class="links">
<!--            <li><a href="#">FAQ</a></li>-->
            <li><a href="flyprovapl@gmail.com">Contact us</a></li>
<!--            <li><a href="#">Cookie Policy</a></li>-->
<!--            <li><a href="#">Privacy policy</a></li>-->
            <li><a href="https://flyprova.pl/uploads/files/FlyPL%20Virtual%20Terms%20and%20Conditions.pdf">Terms and Conditions</a></li>
          </ul>
        </div>
        <div v-if="ExportLang" >
          <h1 class="bot-title">Zobacz</h1>
          <ul class="links">
            <li><a href="https://discord.gg/6r3qbFmBvZ"><font-awesome-icon icon="fa-brands fa-discord" class="brand-logo"/>Nasz serwer Discord</a></li>
            <li><a href="https://www.facebook.com/groups/1424521637918052"><font-awesome-icon icon="fa-brands fa-facebook" class="brand-logo"/>Naszą stronę na Facebook'u</a></li>
          </ul>
        </div>
        <div v-else>
          <h1 class="bot-title">Check this</h1>
          <ul class="links">
            <li><a href="https://discord.gg/6r3qbFmBvZ"><font-awesome-icon icon="fa-brands fa-discord" class="brand-logo"/>Our Discord server</a></li>
            <li><a href="https://www.facebook.com/groups/1424521637918052"><font-awesome-icon icon="fa-brands fa-facebook" class="brand-logo"/>Our Facebook site</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom-footer-container">
      <h2 class="designed-by">© 2023 FlyPL. Designed by agor.</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: "BottomFooter",
  props: {
    ExportLang: {
      type: Boolean,
      default: true
    },
  },
  data()
  {
    return {

    }
  }
}
</script>
<style scoped>
  .footer-container {
    width: 95vw;
    height: 30vh;
    background: var(--light-blue-color);
    margin-top: 100px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .footer-sides-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left-footer-container {
    width: 40vw;
    height: 80%;
  }

  .right-footer-container {
    width: 40vw;
    height: 80%;
    display: flex;
    justify-content: space-around;
  }

  .bot-title {
    color: #ffffff;
    font-weight: 800;
    margin: 0;
    margin-bottom: 20px;
  }

  #left-subtitle {
    color: #ffffff;
    width: 30vw;
  }

  .links {
    list-style-type: none;
    padding-left: 0;
  }

  .links a {
    color: #ffffff;
    text-decoration: none;
    transition: all .2s;
    font-size: large;
  }

  .links a:hover {
    color: #DBE2EF;
  }

  .designed-by {
    color: #ffffff;
    margin-top: 70px;
  }

  .brand-logo {
    margin-right: 5px;
  }

  @media screen and (max-width: 1200px) {
    .footer-container {
      font-size: 8px !important;
      padding: 0;
      width: 100vw;
      height: 50vh;
    }

    .right-footer-container {
      width: 50vw;
      display: flex;
      justify-content: space-between;
    }

    .links li a { font-size: 8px !important}
  }
</style>
