<template>
  <div class="flexbox">
    <div class="main-content" id="mainContent">
      <span class="flypl">FlyPL Virtual</span>
      <h1  v-if="ExportLang" class="main-title" >Zdobywaj, ucz się, doświadczaj. Lataj z najlepszymi.</h1>
      <h1  v-else class="main-title">Fly with the best ones</h1>
    </div>
<!--    <div v-if="exportlang" class="main-content" id="mainContent">-->
<!--      <span class="flypl">FlyPL Virtual</span>-->
<!--      <h1 class="main-title">Acquire, learn, experience. Fly with the best.</h1>-->
<!--    </div>-->
  </div>
</template>
<script>

export default {
  name: "MainContent",
  created() {

  },
  props: {
    ExportLang: {
      type: Boolean,
      default: false
    },
  },
  data()
  {
    return {

    }
  }
}
</script>
<style scoped>
  .main-content {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    box-sizing: border-box;
    padding: 15px;
    background-image: url("../assets/1.png");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
  }

  .line {
    margin-left: 50px;
  }

  .main-title {
    color: var(--bg-color-white);
    font-weight: 800;
    margin: 0;
    font-size: 70px;
    width: 54vw;
    word-spacing: 10px;
    margin-left: 250px;
    display: flex;
  }

  .flypl {
    font-weight: 900;
    font-size: 80px;
    color: var(--light-blue-color);
    margin-left: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flexbox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media screen and (max-width: 1200px) {
    .main-content {
      padding: 0px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .flypl {
      font-size: 40px;
      margin: 0;
      text-align: center;
    }

    .main-title {
      margin: 0;
      font-size:30px;
      text-align: center;
      justify-content: center;
    }
  }
</style>
