<template>
  <div class="topnav-container">
    <div class="topnav-left-elements">
      <SwitchLang @change="onChange" :initLang="initLang" :options="options"/>

      <span class="logo" @click="scrollToElement('mainContent')"><img src="@/assets/logo_flypl.png"></span>


    </div>
    <span class="logo2" @click="$redirect('wp.pl')"> <a href="https://vatsim.net/"> <img src="@/assets/VATSIM.png"> </a></span>
    <span class="logo2" @click="$redirect('wp.pl')"> <a href="https://ivao.aero"> <img src="@/assets/logo_ivao_partner.svg"> </a></span>
    <div  class="topnav-right-elements" v-if="LanguagePL">
      <span class="topnav-rlink hover-effect" @click="scrollToElement('aboutUs')">O Nas</span>
      <span class="topnav-rlink hover-effect" @click="scrollToElement('offers')">Co Oferujemy</span>
      <span class="topnav-rlink hover-effect" @click="scrollToElement('joinToUs')">Dołącz Do Nas</span>
      <a class="topnav-rlink hover-effect" href="https://vms.flyprova.pl/dashboard">Crew Center</a>
      <span class="topnav-bars"><font-awesome-icon icon="fa-solid fa-bars" /></span>

     <span class="topnav-rlink hover-effect" @click="lang_pl"> <country-flag   shadow='true' rounded="true" country='pl' size='normal'/>
     </span>
      <span class="topnav-rlink hover-effect" @click="lang_en">   <country-flag   country='gb' size='normal'/> </span>
    </div>
    <div  class="topnav-right-elements" v-if="!LanguagePL">
      <span class="topnav-rlink hover-effect" @click="scrollToElement('aboutUs')">About Us</span>
      <span class="topnav-rlink hover-effect" @click="scrollToElement('offers')">What we offer</span>
      <span class="topnav-rlink hover-effect" @click="scrollToElement('joinToUs')">Join Us</span>
      <a class="topnav-rlink hover-effect" href="https://vms.flyprova.pl/dashboard">Crew Center</a>
      <span class="topnav-bars"><font-awesome-icon icon="fa-solid fa-bars" /></span>

      <span class="topnav-rlink hover-effect" @click="lang_pl"> <country-flag    country='pl' size='normal'/>
     </span>
      <span class="topnav-rlink hover-effect" @click="lang_en">   <country-flag  shadow='true' rounded="true" country='gb' size='normal'/> </span>

    </div>
  </div>
</template>
<script>
import CountryFlag from 'vue-country-flag-next'
export default {
  data(){
    return{
      LanguagePL: true
    }
  },
   components: {
     CountryFlag
        },
  name: 'TopNavbar',
  methods: {
        lang_pl() {
          this.LanguagePL = true
          this.$emit("lang", true)
        },
    lang_en() {
          this.LanguagePL = false
      this.$emit("lang", false)
    },
    scrollToElement(index) {
      document.getElementById(index).scrollIntoView({behavior: 'smooth'});
    }
  },
    }


</script>
<style scoped>
  .topnav-container {
    width: 100vw;
    height: 10vh;
    background: var(--bg-color-white);
    border-bottom: 1px solid #c0c3c7;
    padding: 14px;
    box-sizing: border-box;
    position: fixed;
    overflow: hidden;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    z-index: 1000;
  }

  .topnav-right-elements {
    color: var(--text-color-gray);
    width: 40vw;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    font-weight: 400;
    font-size: 20px;
  }

  .topnav-rlink {
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: all .2s;
  }

  .topnav-rlink:hover {
    color: var(--light-blue-color);
  }

  .topnav-rlink::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: var(--light-blue-color);
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .2s ease-in-out;
  }

  .topnav-rlink:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }

  .topnav-right-elements a {
    text-decoration: none;
    color: var(--text-color-gray);
  }

  .topnav-bars {
    font-size: 24px;
    display: none;
  }

  .logo {
    width: 130px;
  }
  .logo2 {
    width:130px;
  }

  .logo img {
    width: 130px;
    height: auto;
    cursor: pointer;
  }
  .logo2 img {
    width: 110px;
    height: auto;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    .logo { display: none }
    .topnav-right-elements { width: 100vw }
    .topnav-rlink { font-size: 12px}
    .logo { width: 50px}
  }

</style>
