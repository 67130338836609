<template>
  <div class="info-container">
    <div class="who-we-are" id="aboutUs">
      <div class="info">
        <div class="title-container" v-motion-pop-visible-once>
          <h1 v-if="ExportLang" class="title">Kim Jesteśmy?</h1>
          <h1 v-else class="title">Who we are?</h1>
        </div>
        <div class="flexbox">
          <div v-if="ExportLang" class="article" v-motion-pop-visible-once>
            <p><span style="font-weight: 700">Jesteśmy wiodącą polską wirtualną linią lotniczą w świecie Virtual Aviation.</span> FlyPL to miejsce, które łączy pasjonatów lotnictwa. Młodych i starych. Amatorów jak i profesjonalistów. To przestrzeń w której poznasz ludzi takich jak Ty - entuzjastów podniebnych wojaży. Z naszym systemem polecisz wyżej i dalej.</p>
            <button class="btn btn-join" @click="scrollToElement('joinToUs')">Dołącz do nas!</button>
          </div>
          <div v-else class="article" v-motion-pop-visible-once>
            <p><span style="font-weight: 700">We are leading Polish virtual airline in the world of Virtual Aviation.</span> FlyPL is a place that connects aviation enthusiasts. Young and old. Amateurs and professionals. This is a space where you will meet people like you - sky travel enthusiasts. With our system you will fly higher and further.</p>
            <button class="btn btn-join" @click="scrollToElement('joinToUs')">Join Us!</button>
          </div>
          <div class="list-container" v-motion-pop-visible-once>
            <ul v-if="ExportLang">
              <p id="list-title">Nasza linia posiada:</p>
              <li>Profesjonalną kadrę pilotów</li>
              <li>Bogaty park maszyn lotniczych</li>
              <li>Zaawansowany system obsługi załogi</li>
              <li>Wsparcie większości symulatorów</li>
              <li>Innowacyjne oprogramowanie do śledzenia lotów</li>
              <li>Pełną obsługę techniczną</li>
            </ul>
            <ul v-else>
              <p id="list-title">Our virtual airline has:</p>
              <li>Professional virtual pilots</li>
              <li>A large selection of planes</li>
              <li>Advanced crew system</li>
              <li>Support for most simulators</li>
              <li>Innovative flight tracking software</li>
              <li>Full technical support</li>
            </ul>
          </div>
        </div>
        <div class="map-container" v-motion-pop-visible-once>
          <img class="map" src="@/assets/map.png">
          <h2 v-if="ExportLang" id="airports-value">{{ this.airports + "+" }} <span>Lotnisk</span></h2>
          <h2 v-else id="airports-value">{{ this.airports + "+" }} <span>Airports</span></h2>
        </div>
      </div>
    </div>

    <div class="offers" id="offers">
      <div class="info">
        <div class="title-container" v-motion-pop-visible-once>
          <h1 v-if="ExportLang" class="title">Co Oferujemy?</h1>
          <h1 v-else class="title">What we offer?</h1>
        </div>
        <div class="offers-lists-container">
          <ul class="first-list" v-motion-pop-visible-once>
            <li>
              <font-awesome-icon icon="fa-solid fa-plane-departure" class="list-icon"/>
              <div class="list-sort">
                <h2 v-if="ExportLang">Regularne loty i imprezy grupowe!</h2>
                <h2 v-else>Regular flights and group events!</h2>
              </div>
            </li>
            <li>
              <font-awesome-icon icon="fa-solid fa-globe" class="list-icon"/>
              <div class="list-sort">
                <h2 v-if="ExportLang">Nowoczesny system zarządzania</h2>
                <h2 v-else>Modern management system</h2>
              </div>
            </li>
            <li>
              <font-awesome-icon icon="fa-solid fa-route" class="list-icon"/>
              <div class="list-sort">
                <h2 v-if="ExportLang">Wolność w wyborze tras lotów</h2>
                <h2 v-else>Freedom choose of flight plans</h2>
              </div>
            </li>
          </ul>
          <ul class="second-list" v-motion-pop-visible-once>
            <li>
              <font-awesome-icon icon="fa-sharp fa-layer-group" class="list-icon"/>
              <div class="list-sort">
                <h2 v-if="ExportLang">Wirtualne biuro pilota</h2>
                <h2 v-else>Pilot's virtual office</h2>
              </div>
            </li>
            <li>
              <font-awesome-icon icon="fa-solid fa-arrows-rotate" class="list-icon"/>
              <div class="list-sort">
                <h2 v-if="ExportLang">Aktualizacje danych 24h</h2>
                <h2 v-else>24h data updates</h2>
              </div>
            </li>
            <li>
              <font-awesome-icon icon="fa-sharp fa-layer-group" class="list-icon"/>
              <div class="list-sort">
                <h2 v-if="ExportLang">Dobrą zabawę!</h2>
                <h2 v-else>Good fun!</h2>
              </div>
            </li>
          </ul>
        </div>
        <h2 v-if="ExportLang" class="spacing-h2">Znajdziesz u nas również:</h2>
        <h2 v-else class="spacing-h2">In FlyPl Virtual you will also find:</h2>
        <div class="offers-lists-container second-list-cont">
          <ul class="first-list" v-motion-pop-visible-once>
            <li v-if="ExportLang">
              <font-awesome-icon icon="fa-sharp fa-layer-group" class="list-icon"/>
              <div class="list-sort">
                <h2>Integrację z FlightRadar24</h2>
                <p>Dzięki nowoczesnemu API możesz bookować autentyczne loty odbywające się w czasie rzeczywistym. Z każdego zakątka ziemi! Jeden przycisk i twój plan lotu już na Ciebie czeka!</p>
              </div>
            </li>
            <li v-else>
              <font-awesome-icon icon="fa-sharp fa-layer-group" class="list-icon"/>
              <div class="list-sort">
                <h2>Integration with FlightRadar24</h2>
                <p>Thanks to a modern API, you can book authentic flights in real time. From every corner of the earth! One button and your flight plan is waiting for you!</p>
              </div>
            </li>
            <li v-if="ExportLang">
              <font-awesome-icon icon="fa-brands fa-discord" class="list-icon"/>
              <div class="list-sort">
                <h2>Społeczność Discord</h2>
                <p>Masz problem? Nie chcesz więcej wykonywać samotnych lotów? Marzysz o rozmowie z ludźmi, którzy mają takie same zainteresowania jak Ty? Bez względu na to kim jesteś tu znajdziesz wsparcie, znajomych - wszystko czego potrzebujesz zarówno na ziemi jak i w powietrzu!</p>
              </div>
            </li>
            <li v-else>
              <font-awesome-icon icon="fa-brands fa-discord" class="list-icon"/>
              <div class="list-sort">
                <h2>Discord Community</h2>
                <p>You have a problem? Don't want to fly solo anymore? Do you dream of talking to people who have the same interests as you? No matter who you are, here you will find support, friends - everything you need, on the ground or in the air!</p>
              </div>
            </li>

            <li v-if="ExportLang">
              <font-awesome-icon icon="fa-solid fa-layer-group" class="list-icon"/>
              <div class="list-sort">
                <h2>Wiele Możliwości</h2>
                <p>Dziesiątki tysięcy gotowych tras, propozyje oparte o Twoją lokalizację, pełna integracja z Simbrief bez opuszczania biura pilota, interaktywna wtyczka pogodowa Windy to tylko kilka z dziesiątek zaimplementowanych rozwiązań w naszym biurze pilota.</p>
              </div>
            </li>
            <li v-else>
              <font-awesome-icon icon="fa-solid fa-layer-group" class="list-icon"/>
              <div class="list-sort">
                <h2>Many possibilities</h2>
                <p>Tousands of ready-made routes, suggestions based on your location, full integration with Simbrief without leaving the pilot's office, the Windy interactive weather plugin are just a few of the dozens solutions implemented in our pilot's office.</p>
              </div>
            </li>
          </ul>
          <img src="@/assets/emirates.jpg" id="list-photo" v-motion-pop-visible-once>
        </div>
      </div>
    </div>

    <div v-if="ExportLang" class="gallery" id="gallery">
      <div class="info">
        <div class="title-container" v-motion-pop-visible-once>
          <h1 class="title">Galeria Zdjęć</h1>
        </div>
        <h2 class="subtitle" v-motion-pop-visible-once>Sprawdź niektóre z naszych niesamowitych zrzutów ekranu wykonanych przez najlepszych pilotów naszej linii!</h2>
        <div class="slider" v-motion-pop-visible-once>
            <div v-for="i in [currentIndex]" :key="i">
              <img :src="currentImg" />
            </div>
          <span class="prev" @click="prev">&#10094;</span>
          <span class="next" @click="next">&#10095;</span>
        </div>
      </div>
    </div>

    <div v-else class="gallery" id="gallery">
      <div class="info">
        <div class="title-container" v-motion-pop-visible-once>
          <h1 class="title">Gallery</h1>
        </div>
        <h2 class="subtitle" v-motion-pop-visible-once>Check out some of our amazing screenshots taken by the best pilots in our virtual airline!</h2>
        <div class="slider" v-motion-pop-visible-once>
            <div v-for="i in [currentIndex]" :key="i">
              <img :src="currentImg" />
            </div>
          <span class="prev" @click="prev">&#10094;</span>
          <span class="next" @click="next">&#10095;</span>
        </div>
      </div>
    </div>

    <div  v-if="ExportLang" class="how-to-join" id="joinToUs">
      <div class="info">
        <div class="title-container" v-motion-pop-visible-once>
          <h1 class="title">Dołącz Do Nas!</h1>
        </div>
        <h2 class="subtitle" v-motion-pop-visible-once>Dołączenie do naszej załogi nie jest takie trudne! Jeśli chcesz zawitać w naszej linii., postępuj zgodnie z tym krótkim poradnikiem.</h2>
        <div class="tutorial-container" v-motion-pop-visible-once>
          <div class="tutorial-element">Kliknij w przycisk <span>Wyślij Formularz</span>, znajdujący się poniżej poradnika.</div>
          <div class="tutorial-element">Wpisz dane, które wymaga od Ciebie nasz system rejestrujący, zaznacz odpowiednie zgody i naciśnij Register.</div>
          <div class="tutorial-element">Oczekuj na naszą odpowiedź, która przyjdzie na Twój E-Mail.</div>
          <div class="tutorial-element">Po wysłaniu formularza dołącz na nasz serwer discord oraz wejdź na kanał w celu weryfikacji.</div>
          <div class="buttons-container">
            <a class="btn btn-join btn-send-form" href="https://vms.flyprova.pl/register">Wyślij Formularz</a>
            <a class="btn btn-join" href="https://discord.gg/6r3qbFmBvZ">Nasz Discord</a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="how-to-join" id="joinToUs">
      <div class="info">
        <div class="title-container" v-motion-pop-visible-once>
          <h1 class="title">Join us!</h1>
        </div>
        <h2 class="subtitle" v-motion-pop-visible-once>Joining our crew is not that hard! If you want to join our virtual airline, follow this short guide.</h2>
        <div class="tutorial-container" v-motion-pop-visible-once>
          <div class="tutorial-element">Click the <span>Send Form button</span>, below the guide.</div>
          <div class="tutorial-element">Enter the data that our registration system requires from you, select the appropriate consents and press Register.</div>
          <div class="tutorial-element">Wait for our reply, which will come to your E-Mail.</div>
          <div class="tutorial-element">After sending the form, join our discord server in order to verify.</div>
          <div class="buttons-container">
            <a class="btn btn-join btn-send-form" href="https://vms.flyprova.pl/register">Send Form</a>
            <a class="btn btn-join" href="https://discord.gg/6r3qbFmBvZ">Our Discord</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfoContainer",
  data() {
    return {
      airports: 16428,
      images: [
        "https://cdn.discordapp.com/attachments/899351782822641724/1033526320879116368/2021-11-01_14_40_01-Greenshot.png",
        "https://cdn.discordapp.com/attachments/899351782822641724/1033526321495691354/2021-11-26_03_14_57-Greenshot.png",
        "https://cdn.discordapp.com/attachments/899351782822641724/1033526322242265129/2021-12-07_5.jpg",
        "https://cdn.discordapp.com/attachments/899351782822641724/1033526322921754644/7.png"
      ],
      timer: null,
      currentIndex: 0
    }
  },
  props: {
    ExportLang: {
      type: Boolean,
      default: false
    },
  },

  mounted() {
    this.startSlide();
  },

  methods: {
    scrollToElement(index) {
      document.getElementById(index).scrollIntoView({behavior: 'smooth'});
    },

    startSlide: function() {
      this.timer = setInterval(this.next, 4000);
    },

    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    }
  },

  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
}
</script>
<style scoped>
  .info-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }

  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .info .article {
    color: var(--text-color-gray);
    width: 25vw;
    font-size: 20px;
    text-align: left;
  }

  .info h1 {
    font-size: 50px;
    font-weight: 900;
  }

  .info {
    position: relative;
  }

  .flexbox {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
  }

  .list-container ul {
    font-size: 22px;
    margin-left: 200px;
    color: var(--light-blue-color);
    font-weight: 800;
  }

  .list-container li {
    transition: all .2s;
  }

  .list-container ul li:hover {
    transform: scale(1.1);
    padding: 10px;
  }

  #list-title {
    font-size: 28px;
    color: var(--text-color-gray);
    margin: 0;
    margin-left: -20px;
    margin-bottom: 10px;
  }

  .btn-join {
    border: 5px solid var(--light-blue-color);
    width: 10vw;
    height: 7vh;
    font-weight: 800;
    color: var(--light-blue-color);
    font-size: 19px;
    transition: all .2s;
  }

  .btn-join:hover {
    color: #ffffff;
    background: var(--light-blue-color);
    -webkit-box-shadow: 8px 8px 0px 5px rgba(0, 180, 216, 0.24);
    -moz-box-shadow: 8px 8px 0px 5px rgba(0, 180, 216, 0.24);
    box-shadow: 8px 8px 0px 5px rgba(0, 180, 216, 0.24);
  }

  .shape1 {
    right: -8%;
    top: 150%;
    transform: skewY(-20deg);
  }

  .map-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 70vw;
  }

  #airports-value {
    color: #ffffff;
    background: var(--light-blue-color);
    border-radius: 50%;
    width: 6vw;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
  }

  #airports-value span {
    font-size: 16px;
  }

  .offers-lists-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    overflow: visible;
  }

  .second-list-cont {
    justify-content: flex-start;
  }

  .offers-lists-container ul {
    padding-left: 0;
    margin: 30px;
  }

  .offers-lists-container ul li {
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
  }

  .offers-lists-container h2 {
    color: var(--text-color-gray);
    margin-bottom: 0;
  }

  .offers-lists-container p {
    width: 24vw;
    margin-top: 5px;
    color: var(--text-color-gray);
  }

  .list-icon {
    color: var(--light-blue-color);
    font-size: 50px;
    margin-top: 12px;
  }

  .list-sort {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .first-list li h2 {
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .second-list li h2 {
    margin-left: 15px;
    margin-bottom: 15px;
  }

  .spacing-h2 {
    color: var(--light-blue-color);
    font-size: 28px;
  }

  #list-photo {
    width: 40vw;
    border-radius: 15px;
    margin-left: 100px;
    -webkit-box-shadow: 30px 30px 0px 5px rgba(0, 180, 216, 0.24);
    -moz-box-shadow: 30px 30px 0px 10px rgba(0, 180, 216, 0.24);
    box-shadow: 30px 30px 0px 5px rgba(0, 180, 216, 0.24);
  }

  .subtitle {
    color: var(--text-color-gray);
    width: 50vw;
    text-align: center;
  }

  .tutorial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
  }

  .tutorial-container a {
    text-decoration: none;
  }

  .tutorial-element {
    color: var(--text-color-gray);
    margin: 10px;
    font-size: 20px;
    text-align: center;
  }

  .btn-send-form {
    margin-top: 15px;
    width: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .btn-send-form:hover {
    -webkit-box-shadow: 8px 8px 0px 5px rgba(0, 180, 216, 0.24);
    -moz-box-shadow: 8px 8px 0px 5px rgba(0, 180, 216, 0.24);
    box-shadow: 8px 8px 0px 5px rgba(0, 180, 216, 0.24);
  }

  .slider img {
    height: 550px;
    width: 100%;
    border-radius: 20px;
    margin-top: 20px;
  }

  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 45%;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.7s ease;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    user-select: none;
  }

  .next {
    right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .prev {
    left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .slider {
    height: 600px;
    width: 100%;
    overflow: hidden;
  }

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.3);
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .buttons-container a {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 15vw;
  }

  @media screen and (max-width: 1200px) {
    .title { font-size: 25px !important; }
    .flexbox { flex-direction: column }
    .list-container ul { margin: 0 }
    .article {
      justify-content: center;
      align-items: center;
      width: 100vw !important;
      text-align: center !important;
    }

    .btn-join {
      width: 60vw;
      margin-bottom: 10px;
    }

    .map-container {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
    }

    .map { width: 110vw}
    #airports-value {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }

    #airports-value span {
      font-size: 8px;
    }

    .offers-lists-container {
      flex-direction: column;
    }

    .spacing-h2 {
      text-align: center;
    }

    .list-sort p { width:70vw; }

    #list-photo { display: none }

    .subtitle {
      margin: 0 !important;
      text-align: center !important;
      width: 100vw;
      margin-top: 20px;
    }

    .slider img {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }

    .prev, .next {
      top: 17%;
    }

    .slider {
      height: 250px;
    }

    .tutorial-container {
      width: 100vw;
      justify-content: center;
      align-items: center;
    }

    .buttons-container {
      width: 100vw;
      text-align: center;
    }

    .buttons-container a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      width: 50vw;
    }
  }
</style>
