import { createApp } from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
// import { faRadar } from "@fortawesome/sharp-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faRoute } from "@fortawesome/free-solid-svg-icons";
// import { faTowerControl } from "@fortawesome/sharp-solid-svg-icons";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// import { faMessageSmile } from "@fortawesome/sharp-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { MotionPlugin } from "@vueuse/motion";
import CountryFlag from 'vue-country-flag-next'

library.add(faUser, faLayerGroup, faDiscord, faLayerGroup, faPlaneDeparture, faGlobe, faRoute,  faArrowsRotate, faFacebook,  faBars)


createApp(App).component('font-awesome-icon', FontAwesomeIcon).component('country-flag', CountryFlag).use(MotionPlugin).mount('#app')
